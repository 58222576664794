@import "./font.scss";
@import "./reset.scss";
@import "./border.scss";

html,
body, 
#root {
    box-sizing: border-box;
    width: 100vw;
    height: 100vh;
    // overflow-x: hidden;
}

*,
*::after,
*::before {
    box-sizing: inherit;
    // user-select: none;
}

input, input[type=search] {
    outline: 0;
    -webkit-appearance: none !important; 
}

::-webkit-scrollbar {
    display: none;
}

